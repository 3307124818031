import '@splidejs/react-splide/css/core';
import './src/styles/app.scss';
import './src/styles/colour-scheme.scss';

export function shouldUpdateScroll() {
  window.scrollTo(0, 0);
  const body = document.getElementsByTagName('body')[0];
  // Setting the scroll on body is required because scrolling to top doesn't work with
  // a height: 100% set on the body component, but we can't get rid of that because of
  // a Safari bug that's adding an empty margin to the right
  body.scrollTop = 0;
  return false;
}
