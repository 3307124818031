const LOWER_SCREEN_SIZE = 320;
const UPPER_SCREEN_SIZE = 1400;

// UTILS
// Parked here due to import/compile issues

// Returns a fluid clamp value based on screen size. Min and max values are in pixels.
export const fluidPx = ({ min, max }: { min: number; max: number }) => [
  `clamp(${min}px, calc(${min}px + (${max} - ${min}) * ((100vw - ${LOWER_SCREEN_SIZE}px) / (${UPPER_SCREEN_SIZE} - ${LOWER_SCREEN_SIZE}))), ${max}px)`,
];

// Takes pixel values and returns a fluid font size and line height. Line height is set to 1.5 by default.
export const fluidTypeSize = ({ min, max }: { min: number; max: number }) => [
  fluidPx({ min, max }),
];
