import { fluidTypeSize } from '../utils/fluidSizing';

export const breakpoints = {
  sm: '450px',
  md: '700px',
  lg: '950px',
  xlg: '1150px',
};

export const theme = {
  breakpoint: breakpoints,
  breakpoints: Object.values(breakpoints),
  space: [
    '0rem', // 0 - 0px
    '0.25rem', // 1 - 5px
    '0.5rem', // 2 - 10px
    '0.75rem', // 3 - 15px
    '1rem', // 4 - 20px
    '1.25rem', // 5 - 25px
    '1.5rem', // 6 - 30px
    '1.75rem', // 7 - 35px
    '2.5rem', // 8 - 50px
    '3.75rem', // 9 - 75px
    '5rem', // 10 - 100px
  ],
  fonts: {
    body: 'FoundersGrotesk, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading:
      'FoundersGrotesk, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    headingBig:
      'Minipax, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
  },
  fontSizes: [
    '0.6rem', // 0 - 12px
    '0.7rem', // 1 - 14px
    '0.8rem', // 2 - 16px
    '0.9rem', // 3 - 18px
    '1rem', // 4 - 20px
    '1.05rem', // 5 - 21px
    '1.2rem', // 6 - 24px
    '1.5rem', // 7 - 30px
    '1.7rem', // 8 - 34px
    '1.8rem', // 9 - 36px
    '2.7rem', // 10 - 54px
  ],
  fontWeights: {
    body: 300,
    normal: 'normal',
    bold: 700,
    heading: 700,
  },
  lineHeights: {
    body: 1.4,
    heading: 1.33,
  },
  colors: {
    text: '#121212',
    greyText: '#c6c6c6',
    lightText: '#fff',
    background: '#fff',
    black: '#121212',
    grey: '#F4F4F4',
    darkGrey: '#979797',
    lightGreen: '#white',
    darkGreen: '#18342F',
    pink: '#ffffff',
    yellow: '#F4DB42',
    red: '#121212',
    lightRed: '#fc8ea3',
  },
  text: {
    headingBig: {
      fontFamily: 'headingBig',
      fontWeight: 'bold',
      lineHeight: 'heading',
    },
    headingSmall: {
      fontFamily: 'heading',
      fontWeight: 'bold',
      lineHeight: 'heading',
    },
    body: {
      fontSize: fluidTypeSize({
        min: 18,
        max: 20,
      }),
      fontFamily: ['FoundersGrotesk', 'sans-serif'],
      lineHeight: '1.2',
      fontWeight: 300,
      letterSpacing: '0.02em',
    },
    bodyLrg: {
      fontSize: fluidTypeSize({
        min: 18,
        max: 20,
      }),
      fontFamily: ['FoundersGrotesk', 'sans-serif'],
      lineHeight: '1.2',
      fontWeight: 300,
      letterSpacing: '0.02em',
    },
    bodySml: {
      fontSize: fluidTypeSize({
        min: 18,
        max: 18,
      }),
      fontFamily: ['FoundersGrotesk', 'sans-serif'],
      lineHeight: '1.2',
      fontWeight: 'normal',
      letterSpacing: '0.02em',
    },
    h1: {
      fontSize: fluidTypeSize({
        min: 36,
        max: 64,
      }),
      fontFamily: ['Helvetica', 'sans-serif'],
      lineHeight: '1.2',
      fontWeight: 'normal',
    },
    h2: {
      fontSize: fluidTypeSize({
        min: 24,
        max: 36,
      }),
      fontFamily: ['Helvetica', 'sans-serif'],
      lineHeight: '1.2',
      fontWeight: 'normal',
    },
    h3: {
      fontSize: fluidTypeSize({
        min: 24,
        max: 36,
      }),
      fontFamily: ['FoundersGrotesk', 'sans-serif'],
      lineHeight: '1.1',
      fontWeight: 'normal',
      letterSpacing: '0.02em',
    },
    h4: {
      fontSize: fluidTypeSize({
        min: 18,
        max: 24,
      }),
      fontFamily: ['FoundersGrotesk', 'sans-serif'],
      lineHeight: '1.1',
      fontWeight: 'normal',
      letterSpacing: '0.02em',
    },
    h5: {
      fontSize: '14px',
      fontFamily: ['FoundersGrotesk', 'sans-serif'],
      lineHeight: '1.1',
      fontWeight: 500,
      letterSpacing: '0.15em',
    },
    h6: {
      fontSize: '14px',
      fontFamily: ['FoundersGrotesk', 'sans-serif'],
      lineHeight: '1.1',
      fontWeight: 500,
      letterSpacing: '0.15em',
    },
    link: {
      color: 'red',
      cursor: 'pointer',
      fontWeight: 'bold',
      textDecoration: 'none',
      transition: '0.2s ease-in-out',
      '&:hover': {
        color: 'lightRed',
      },
    },
    linkLrg: {
      variant: 'text.link',
      fontSize: [3, , 6],
    },
    linkSml: {
      variant: 'text.link',
      fontSize: [1, , 2],
    },
    bold: {
      fontWeight: 'bold',
    },
    p: {
      fontSize: fluidTypeSize({
        min: 18,
        max: 20,
      }),
      fontFamily: ['FoundersGrotesk', 'sans-serif'],
      lineHeight: '1.2',
      fontWeight: 300,
      letterSpacing: '0.02em',
      marginTop: 0,
      marginBottom: '1em',
      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
  styles: {
    root: {
      // variant: 'text.body',
      // TODO: Figure out default body sizes
      fontSize: fluidTypeSize({
        min: 18,
        max: 20,
      }),
      'a,button,input,textarea,select,details,[tabindex]:not([tabindex="-1"])':
        {
          outlineColor: 'darkorange',
          outlineOffset: '2px',
          outlineStyle: 'solid',
          outlineWidth: 0,
          transition: 'outline 100ms',
          '&:focus': {
            outlineWidth: '2px',
          },
          '&:focus:not(:focus-visible)': {
            outline: 0,
          },
          '&:focus-visible': {
            outlineWidth: '2px',
          },
        },
    },
  },
};
